<template>
  <div>
    <div class="wrok-theme-create">
      <div class="manage-wrapper">
        <div class="wrok-theme-create-container">
          <h3 class="create_title">创建作业主题</h3>
          <div class="content">
            <el-form label-width="130px">
              <el-form-item label="主题类型:">
                <el-radio-group v-model="themetype">
                  <el-radio :label="1">作业</el-radio>
                  <el-radio :label="2">测试</el-radio>
                  <el-radio :label="3">问卷</el-radio>
                  <el-radio :label="4">普通话测试</el-radio>
                  <el-radio :label="5">书法前测</el-radio>
                  <el-radio :label="6">书法后测</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="主题名称:" size="mini">
                <el-row>
                  <el-col :span="12">
                    <el-input placeholder="请输入主题名称" maxlength=60 v-model="themename" />
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="主题封面:">
                <el-row>
                  <el-col :span="5">
                    <div class="default">
                      <div>
                        <img :src="cover" />
                      </div>
                      <span>默认封面</span>
                    </div>
                  </el-col>
                  <el-col :span="5">
                    <div class="upload" @click="openupload(true)">
                      <div>
                        <img src="@/assets/upload.png" />
                        <div slot="tip" class="el-upload__tip">点击上传封面</div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="绑定学科:">
                <el-select v-model="bindsubject" placeholder="请选择" size="mini">
                  <el-option v-for="item in subjectlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="主题绑定:">
                <el-radio-group v-model="themebind" @change="themechange">
                  <el-radio :label="1">课程</el-radio>
                  <el-radio :label="2">活动</el-radio>
                  <el-radio :label="3">项目</el-radio>
                  <el-radio :label="4">不绑定</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item :label="bindname" v-if="themebind==1||themebind==2">
                <el-select v-model="bindvalue" placeholder="请选择" size="mini" @change="coursechange">
                  <el-option v-for="item in courseactivelist" :key="item.value" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="绑定项目:" v-if="themebind==3">
                <el-select v-model="bindproject" placeholder="请选择" @change="projectchange" size="mini">
                  <el-option v-for="item in projectlist" :key="item.value" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
                <el-cascader :options="cascaderlist" :props="{ checkStrictly: true, value: 'id', label: 'name' }"
                  clearable v-model="cascadervalue" @change="cascaderchange" size="mini" style="margin-left:20px">
                </el-cascader>
              </el-form-item>
              <el-form-item label="用户组:" v-if="themebind==3">
                <el-cascader :options="usergroup" :props="{ multiple: true, value: 'id',emitPath:false,}" clearable
                  filterable v-model="usergroupvalue" ref="cascader" size="mini" @change="userGroupChange"
                  collapse-tags>
                </el-cascader>
              </el-form-item>
              <el-form-item class="el-form-item-btns">
                <el-button @click="cancel">取消</el-button>
                <el-button type="primary" @click="confirm">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <!-- 本地断点续传-->
      <el-drawer v-if="!BreakPointUpload" title="" :visible.sync="UploadPicture" @opened="openedDrawer"
        :before-close="handleClose">
        <upimg v-if="UploadPicture" ref="upimg" :upimgload="true" @closeMain="closeMain"></upimg>
      </el-drawer>
      <!-- 阿里云上传 -->
      <el-drawer v-if="BreakPointUpload" title="" :visible.sync="UploadPicture" :before-close="handleClose">
        <ossUploader v-if="UploadPicture" @closeMain="closeMain"></ossUploader>
      </el-drawer>
      <!-- <Footer></Footer> -->
    </div>
  </div>
</template>

<script>
  import cover from "../../assets/task-cover.png"
  export default {
    data() {
      return {
        themetype: 1, //主题类型 1-作业 2-测试 3-问卷 4-普通话测试 5-书法前测 6-书法后测
        themename: "", //主题名称
        bindsubject: '', //绑定学科
        subjectlist: [], //绑定学科数组
        themebind: 4, //主题绑定的值 1-课程 2-活动 3项目 4-不绑定
        UploadPicture: false,
        imgurl: this.downloadURL,
        cover, //客户端显示的主题封面
        usercover: '', //用户上传的主题封面
        courseactivelist: [], //课程或者活动列表
        bindname: '', //绑定***(课程或活动)
        bindvalue: '', //绑定课程或者活动的值
        projectlist: [], //项目列表
        bindproject: '', //绑定项目的期次的值
        cascaderlist: [], //绑定项目期次下的列表
        cascadervalue: [], //绑定项目期次下的列表id的值
        usergroup: [], //用户组
        usergroupvalue: '', //用户组选中的id的值
        usergroupidarr: [], //用户组id数组(接口传参需要)
        bindvalueid: null,
      };
    },

    methods: {

      // 关闭右侧上传抽屉提示弹窗
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      //获取subjectlist列表
      getsubjectlist() {
        this.$Api.Form.getSubject()
          .then(res => {
            this.subjectlist = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      },
      //上传文件相关
      openupload(flag) {
        // console.log("upload")
        this.UploadPicture = flag;
        if (this.myBrowser() == "Chrome") {
          this.upimgload = false;
        } else {
          this.upimgload = true;
        }
      },
      openedDrawer() {
        this.$refs.upimg.$el.querySelector("input").click();
      },
      closeMain(val) {
        // console.log(val);
        this.cover = this.imgurl + val.filemd5;
        this.usercover = val.filemd5
      },
      //获取绑定课程列表
      getcourselist() {
        this.$Api.Form.getCourse(this.$route.query.projectId)
          .then(res => {
            this.courseactivelist = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      },
      //获取绑定活动列表
      getacivelist() {
        this.$Api.Form.getActive(this.$route.query.projectId)
          .then(res => {
            this.courseactivelist = res.data;
          })
          .catch(err => {
            console.log(err);
          });
      },
      //获取绑定项目分期
      getproject() {
        return this.$Api.Form.getPeriod(this.$route.query.projectId)
          .then(res => {
            // console.log(res)
            this.projectlist = res.data;
            return Promise.resolve(this.projectlist)
          })
          .catch(err => {
            console.log(err);
            return Promise.reject(err)
          });
      },
      //主题绑定变化
      themechange(val) {
        this.bindvalue = '';
        this.bindproject = '';
        this.cascadervalue = '';
        this.usergroupvalue = '';
        if (val == 1) {
          this.bindname = "绑定课程"
          this.getcourselist()
        } else if (val == 2) {
          this.bindname = "绑定活动"
          this.getacivelist()
        } else if (val == 3) {
          this.getproject()
        } else {

        }
      },
      //绑定项目期次变化
      projectchange(val) {
        this.getCascader(val)
      },
      //根据项目期次获取对应 cascader
      getCascader(val) {
        let arr = this.projectlist.filter(item => {
          return item.id == val
        })
        console.log('1111111', arr)
        this.cascaderlist = arr[0].children
      },
      //根据项目期次获取对应 cascader 获取对应的用户组
      cascaderchange(val) {
        // console.log(val)
        this.$Api.Form.getUserGroup(val[val.length - 1])
          .then(res => {
            this.usergroup = res.data;
          })
          .catch(err => console.log(err));
      },
      //监听用户组变化(获取用户组id数组用于传参)
      userGroupChange(val) {
        // console.log(val);
        let userGroups = this.$refs["cascader"].getCheckedNodes();
        let useridarr = [];
        userGroups.map(item => {
          useridarr.push(item.value);
        });
        this.usergroupidarr = useridarr;
      },
      //监听绑定课程||活动变化
      coursechange(val) {
        console.log("绑定课程或活动的新值", val)
        this.bindvalueid = val
      },
      //确定按钮
      confirm() {
        //判断必填项目
        if (!this.themename.trim()) {
          this.$alert("请填写主题名名称", "提示");
        } else if (this.themebind == 1 && !this.bindvalue) {
          this.$alert("请选择绑定的课程名称", "提示");
        } else if (this.themebind == 2 && !this.bindvalue) {
          this.$alert("请选择绑定的活动名称", "提示");
        } else if (this.themebind == 3) {
          if (!this.bindproject || !this.cascadervalue || !this.usergroupvalue) {
            this.$alert("请选择绑定的项目或用户组", "提示");
          } else {
            // console.log("提交")
            this.submit()
          }
        } else {
          // console.log("提交")
          this.submit()
        }
      },
      //取消按钮
      cancel() {
        this.$confirm("确认取消创建作业主题？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      },
      //提交作业主题
      submit() {
        this.$confirm("是否提交该作业主题？", "提示", {
            confirmButtonText: "提交",
            cancelButtonText: "取消",
            type: "success"
          })
          .then(() => {
            let params = {
              projectId: this.$route.query.projectId, //二级项目id
              themeName: this.themename, //主题名称
              themeType: this.themetype, //主题类型
              themeCover: this.usercover ? this.usercover : null, //主题封面
              subjectId: this.bindsubject, //绑定学科的id
              bindingId: this.$route.query.edit ? (this.themebind == 3 ? this.cascadervalue[this
                .cascadervalue.length - 1] : this.bindvalueid) : this.bindvalue || this.cascadervalue[this
                .cascadervalue.length - 1], //绑定课程或者活动的id
              themeBinding: this.themebind, //主题绑定的id
              projectPeriodId: this.bindproject, //项目期次id
              projectSublevelId: this.cascadervalue[this.cascadervalue.length - 1], //子集项目id
              userGroupId: this.usergroupidarr, //用户组id数组
              id: this.$route.query.id, //主题id
              createId: this.$route.query.createId //创建者&修改者id
            };
            console.log(params);
            if (this.$route.query.edit) {
              this.$Api.Form.updateTheme(params)
                .then(res => {
                  this.$message({
                    type: "success",
                    message: "修改成功"
                  });
                  // this.$router.push({
                  //   path: "/home/manage/job"
                  // });
                  this.$router.go(-1)
                })
                .catch(err => {
                  console.log(err);
                  this.$message({
                    type: "error",
                    message: "取消修改"
                  });
                });
            } else {
              this.$Api.Form.addTheme(params)
                .then(res => {
                  console.log(res);
                  this.$message({
                    type: "success",
                    message: "提交成功"
                  });
                  this.$router.push({
                    // path: "/design",
                    path:'/edit',
                    query: {
                      themeId: res.data,
                      createId: this.$route.query.createId,
                      themetype: this.themetype,
                      themebind: this.themebind,
                      courseId: this.bindvalue, //绑定课程的课程id
                      isCreate:true,
                      workId:'-1'
                    }
                  });
                })
                .catch(err => {
                  console.log(err);
                  this.$message({
                    type: "error",
                    message: "提交失败"
                  });
                });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消成功"
            });
          })
      },
      //回显作业主题数据
      showData() {
        //判断用户是否点击编辑按钮进入，如果是编辑则表单回填上次数据
        if (this.$route.query.edit) {
          this.$Api.Form.getThemeInfo(this.$route.query.id)
            .then(value => {
              console.log('上次编辑的数据', value);
              this.$route.meta.title = '编辑作业主题'
              this.$store.commit('breadPageNameChange', '编辑作业主题')
              if (value.data.themeBinding == 1) {
                this.getcourselist() //获取绑定课程列表
              } else if (value.data.themeBinding == 2) {
                this.getacivelist() //获取绑定活动列表
              } else if (value.data.themeBinding == 3) {
                this.getproject()
                  .then(res => {
                    console.log("成功--11111", res)
                    this.bindproject = value.data.projectPeriodId; //回显绑定项目期次
                    this.getCascader(value.data.projectPeriodId); //根据项目期次获取对应 cascader
                    this.cascadervalue.push(value.data.projectSublevelId); //回显绑定项目期次下的列表id的值
                    console.log(this.cascadervalue)
                    this.cascaderchange(this.cascadervalue); //根据cascadervalue请求用户组
                  })
                  .catch(err => {
                    console.log("失败--", err)
                  })
              }
              this.themetype = value.data.themeType; //回显主题类型
              this.themename = value.data.themeName; //回显主题名称
              this.bindsubject = value.data.subjectId; //回显绑定学科
              this.themebind = value.data.themeBinding; //回显主题绑定(课程、活动、项目、不绑定)
              this.bindname = value.data.themeBinding == 1 ? "绑定课程" : value.data.themeBinding == 2 ? "绑定活动" : ""
              this.bindvalue = value.data.bindingName; //回显主题绑定课程或者活动的名称
              this.cover = value.data.themeCover ? this.imgurl + value.data.themeCover : cover; //回显主题封面
              this.bindvalueid = value.data.bindingId;
              this.usergroupvalue = value.data.userGroupId;
              this.usergroupidarr = value.data.userGroupId;
              // console.log(this.usergroupvalue);
            })
            .catch(reason => {
              console.log(reason);
            });
        } else {
          // console.log("创建作业主题")
        }
      }
    },
    watch: {},
    computed: {},
    // activated() {
    //   this.showData()
    // },
    mounted() {
      this.getsubjectlist();
      this.showData()
      this.$nextTick(() => {
        this.$route.meta.title = '创建作业主题'
        this.$store.commit('breadPageNameChange', '创建作业主题')
      })
    }
  };
</script>

<style lang="less" scoped>
  .wrok-theme-create {
    // background-color: #fafafa;

    .wrok-theme-create-container {
      margin-bottom: 100px;
      background-color: #ffffff;
      box-shadow: 0px 2px 10px 0px #f5f8fa;
      border-radius: 8px;
      padding: 0 60px;
      overflow: hidden;

      .create_title {
        height: 65px;
        border-bottom: 1px solid #F5F4F4;
        font-size: 16px;
        line-height: 65px;

        &::before {
          content: ' ';
          display: inline-block;
          width: 5px;
          height: 25px;
          background-color: #508EF9;
          border-radius: 5px;
          vertical-align: -6px;
          margin-right: 16px;
        }
      }

      /deep/ .el-form {
        .el-form-item {
          margin-bottom: 25px;
        }

        .el-form-item-btns {
          text-align: left;
          margin-left: 150px !important;
        }
      }

      .content {
        margin: 30px 0 60px;

        .default {
          display: flex;
          flex-direction: column;
          align-items: center;

          div {
            width: 100%;
            height: 125px;
            background: cornflowerblue;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .upload {
          margin-left: 12px;
          width: 100%;
          height: 125px;
          background: #eff0f4;
          display: flex;
          // flex-direction: column;
          align-items: center;
          justify-content: space-around;

        }

        // .btn {
        //   display: flex;
        //   align-items: center;

        //   div:first-child {
        //     width: 161px;
        //     height: 42px;
        //     border: 1px solid #f9f9f9;
        //     background: #ffffff;
        //     box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
        //     border-radius: 5px;
        //     color: #2774ee;
        //     font-size: 18px;
        //     text-align: center;
        //     line-height: 42px;
        //     margin: 0 122px 0 81px;
        //   }

        //   div:last-child {
        //     width: 161px;
        //     height: 42px;
        //     border: 1px solid #f9f9f9;
        //     background: linear-gradient(90deg, #2272ec, #3a7bf6);
        //     box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
        //     border-radius: 5px;
        //     font-size: 18px;
        //     color: #ffffff;
        //     text-align: center;
        //     line-height: 42px;
        //   }
        // }
      }
    }
  }
</style>